import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { moneyFormat } from '~/utils/functions';
import { Produto } from '../../../../../protocols';

export const variaveisColumns: CustomGridColumns[] = [
  {
    field: 'val_desconto',
    headerName: 'Tot. Desconto',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_desconto } = row;
      return <span>{moneyFormat(String(val_desconto || 0), 2)}</span>;
    },
  },
  {
    field: 'val_acrescimo',
    headerName: 'Tot. Acrésc.',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_acrescimo } = row;
      return <span>{moneyFormat(String(val_acrescimo || 0), 2)}</span>;
    },
  },
  {
    field: 'val_tabela_liq',
    headerName: 'Tot. Liquido',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_tabela_liq } = row;
      return <span>{moneyFormat(String(val_tabela_liq), 2, 2)}</span>;
    },
  },
  {
    field: 'val_frete',
    headerName: 'Tot. Frete',
    hide: true,
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_frete } = row;
      return <span>{moneyFormat(String(val_frete), 2)}</span>;
    },
  },
  {
    field: 'val_frete_dest',
    headerName: 'Tot. Frete Dest',
    width: 130,
    hide: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_frete_dest } = row;
      return <span>{moneyFormat(String(val_frete_dest), 2)}</span>;
    },
  },
  {
    field: 'val_ipi',
    headerName: 'Tot. IPI',
    width: 100,
    hide: true,
    sortable: false,
    disableColumnMenu: true,
  },
];
