import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { Produto } from '../../../../../protocols';
import { moneyFormat } from '~/utils/functions';

export const icmsColumns: CustomGridColumns[] = [
  {
    field: 'val_bc_icms',
    headerName: 'BC ICMS',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_bc_icms } = row;
      return <span>{moneyFormat(String(val_bc_icms), 2)}</span>;
    },
  },
  {
    field: 'val_isento',
    headerName: 'Val. Isento',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_isento } = row;
      return <span>{moneyFormat(String(val_isento), 2)}</span>;
    },
  },
  {
    field: 'val_outros',
    headerName: 'Val. Outros',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_outros } = row;
      return <span>{moneyFormat(String(val_outros), 2)}</span>;
    },
  },
  {
    field: 'val_icms',
    headerName: 'Val. ICMS',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_icms } = row;
      return <span>{moneyFormat(String(val_icms), 2)}</span>;
    },
  },
  {
    field: 'per_fcp_icms',
    headerName: '% FCP',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
];
