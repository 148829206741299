import moment from 'moment';
import * as yup from 'yup';

export const schema = yup
  .object({
    loja: yup.number().required(),
    busca_parceiro: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_perfil: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_serie: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    cod_especie: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    dta_emissao: yup
      .string()
      .required('Data de emissão é obrigatória.')
      .nullable()
      .test(
        'is-valid-date',
        'Data de emissão deve ser menor ou igual à data de saída',
        function (value) {
          const entrada = this.parent.dta_saida;
          const today = new Date().toISOString().slice(0, 10); // Data atual no formato AAAA-MM-DD
          return (
            !value ||
            ((!entrada || new Date(value) <= new Date(entrada)) &&
              new Date(value) <= new Date(today))
          );
        },
      ),
    dta_saida: yup
      .string()
      .required('Data de saída é obrigatória.')
      .nullable()
      .test(
        'is-future-or-today',
        'Data de saída deve ser maior ou igual à data atual',
        function (value) {
          const today = new Date().toISOString().slice(0, 10); // Data atual no formato AAAA-MM-DD
          return !value || new Date(value) >= new Date(today);
        },
      ),
  })
  .required();

export const datesScheme = yup
  .object({
    dta_saida: yup
      .string()
      .required('Data de saída deve ser informada')
      .test(
        'maiorData',
        'Data de Emissão deve ser menor ou igual à data de Saída.',
        function (value) {
          const { dta_emissao } = this.parent;
          if (
            moment(value).isAfter(moment(dta_emissao)) ||
            moment(value).isSame(moment(dta_emissao))
          )
            return true;
          return false;
        },
      ),
    dta_emissao: yup
      .string()
      .required('Data de emissão deve ser informada')
      .test(
        'maiorData',
        'Data de Emissão deve ser menor ou igual à data de Saída.',
        function (value) {
          const { dta_saida } = this.parent;
          if (
            moment(value).isBefore(moment(dta_saida)) ||
            moment(value).isSame(moment(dta_saida))
          )
            return true;
          return false;
        },
      ),
  })
  .required();
