import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .table-actions-buttons {
    margin-top: 32px;
    float: right;

    button {
      padding: 6px 16px;
      margin-left: 8px;
      border-radius: 6px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subtotal {
    margin-bottom: 0;
    background-color: #b3f7b3;
    display: block;
    padding: 7px;
    font-size: 1.15rem;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .total-itens {
    font-size: 1.15rem;
  }
  .field-error {
    border-radius: 4px;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;
