import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { icmsColumns } from './Icms';

import { identificadorColumns } from './Idenficador';
import { quantidadeColumns } from './Quantidade';
import { stColumns } from './St';
import { tributacaoColumns } from './Tributacao';
import { variaveisColumns } from './Variaveis';

export const tableItensColumns: CustomGridColumns[] = [
  ...identificadorColumns,
  ...quantidadeColumns,
  ...variaveisColumns,
  ...icmsColumns,
  ...stColumns,
  ...tributacaoColumns,
];
