import React, { useCallback, useState } from 'react';
import Separator from '~/components/Separator';
import { Button, CardReferecia, ContainerReferecia } from './styles';
import * as yup from 'yup';
import { IoIosSearch } from 'react-icons/io';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { Col, Row } from 'react-bootstrap';
import { FormItens } from './FormItens';
import { TableItens } from './TableItens';
import { Modal } from './components/Modal';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  loja: yup.number().required(),
  busca_parceiro: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  cod_perfil: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
});

export const Itens: React.FC = () => {
  const {
    cuponsReferenciados,
    formNFE: { getValues, setError },
    isUpdate,
  } = useEmissaoNFE();

  const [showModal, setShowModal] = useState<boolean>(false);

  const isCupomObrigatorio = useCallback(() => {
    const perfil = getValues('cod_perfil');
    if (perfil !== '' && perfil?.cfops !== undefined) {
      return perfil.cfops.some((r: string) => ['5929', '6929'].indexOf(r) >= 0);
    }
    return false;
  }, [getValues]);

  const handleOpenModal = useCallback(async () => {
    /** Formata dados para validar abertura do modal */
    const perfil = getValues('cod_perfil');
    const dataToValidate = {
      loja: getValues('loja'),
      busca_parceiro: getValues('busca_parceiro'),
      cod_perfil: perfil,
    };
    /** Executa validação dos dados necessários */
    const errors: any = [];
    const errorsList = await schema
      .validate(dataToValidate, { abortEarly: false })
      .catch(function (err) {
        return err.inner;
      });
    /** Adiciona campos que falharam ao array de erros */
    for (let i = 0; i < errorsList.length; i++) {
      const tmp = errorsList[i].path.split('.');
      errors.push(tmp[0]);
      setError(tmp[0], { type: 'custom', message: 'Validation Error' });
    }
    /** Cancela abertura do modal em caso de erros */
    if (errorsList.length > 0) {
      return;
    }
    /** Valida o CFOP */
    if (!isCupomObrigatorio()) {
      toast.warning('Para NF com Cupom, o Perfil deve ter CFOP 5929 ou 6929.');
      return;
    }
    /** Cancela abertura do modal em caso de erros */
    if (errors.length > 0) {
      return;
    }
    /** Abre o modal */
    setShowModal(true);
  }, [getValues, isCupomObrigatorio, setError]);

  return (
    <>
      <Separator labelText="Cupons Vinculados" childrenWidth="140px">
        <Button
          type="button"
          style={{
            marginTop: '-7px',
            cursor: isUpdate ? 'not-allowed' : '',
          }}
          onClick={() => handleOpenModal()}
          disabled={isUpdate}
        >
          <IoIosSearch
            focusable={false}
            aria-hidden="true"
            size={20}
            style={{ marginTop: '-2px', color: 'green', marginRight: '10px' }}
          />
          Buscar Cupom
        </Button>
      </Separator>
      <Row>
        <Col sm={12}>
          <ContainerReferecia>
            {cuponsReferenciados.map((nf) => {
              return (
                <CardReferecia key={nf.cod_seq_cupom}>
                  {nf.num_cupom}
                </CardReferecia>
              );
            })}
          </ContainerReferecia>
        </Col>
      </Row>
      {showModal && (
        <Modal
          handleModal={{
            showModal,
            setShowModal,
          }}
        />
      )}
      {cuponsReferenciados.length <= 0 && !isCupomObrigatorio() && (
        <FormItens />
      )}
      <TableItens />
    </>
  );
};
