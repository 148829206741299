import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { Produto } from '../../../../../protocols';

export const identificadorColumns: CustomGridColumns[] = [
  {
    field: 'num_item',
    headerName: 'Seq',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'flg_bonificado',
    headerName: 'Bonificado',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      return <span>{row.flg_bonificado ? 'Sim' : 'Não'}</span>;
    },
  },
  {
    field: 'cod_produto',
    headerName: 'Código do Produto',
    disableColumnMenu: true,
    width: 130,
    sortable: false,
  },
  {
    field: 'cod_gtin_principal',
    headerName: 'EAN',
    disableColumnMenu: true,
    width: 150,
    sortable: false,
  },
  {
    field: 'num_ncm',
    headerName: 'NCM',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'des_produto',
    headerName: 'Descrição',
    disableColumnMenu: true,
    width: 300,
    sortable: false,
  },
  {
    field: 'cfop',
    headerName: 'CFOP',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'cod_id_ctb',
    hide: true,
    headerName: 'Cod ID CTB',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
];
