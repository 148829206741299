import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { Produto } from '../../../../../protocols';
import { moneyFormat } from '~/utils/functions';

export const stColumns: CustomGridColumns[] = [
  {
    field: 'val_icms_st',
    headerName: 'Valor Subst',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_bc_st',
    headerName: 'Tot BC ST',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_fcp_icms',
    headerName: 'Val. FCP ICMS',
    width: 130,
    hide: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_fcp_icms } = row;
      return <span>{moneyFormat(String(val_fcp_icms), 2)}</span>;
    },
  },
];
