import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { Produto } from '../../../../../protocols';
import { formatCurrencyAsText } from '~/utils/functions';

export const tributacaoColumns: CustomGridColumns[] = [
  {
    field: 'per_icms',
    headerName: '% ICMS',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { per_icms } = row;
      return <span>{formatCurrencyAsText(per_icms)} %</span>;
    },
  },
  {
    field: 'tipo_tributacao',
    headerName: 'Tributação',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      let tipo_trib = '';
      switch (row.tipo_tributacao) {
        case 0:
          tipo_trib = 'Tributado';
          break;
        case 1:
          tipo_trib = 'Isento';
          break;
        case 2:
          tipo_trib = 'Redução';
          break;
        case 3:
          tipo_trib = 'Substituição';
          break;
        case 4:
          tipo_trib = 'Suspensão';
          break;
        case 5:
          tipo_trib = 'Diferido';
          break;
        case 6:
          tipo_trib = 'Outros ICMS';
          break;
        case 7:
          tipo_trib = 'Portaria CAT-38';
          break;
        case 8:
          tipo_trib = 'Não Tributad';
          break;

        default:
          break;
      }
      return <span>{tipo_trib}</span>;
    },
  },
  {
    field: 'val_reducao',
    headerName: '% Red. BC',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_reducao } = row;
      return <span>{formatCurrencyAsText(val_reducao)} %</span>;
    },
  },
];
