import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

import { tableItensColumns } from './TableItens';

import { ScrollButtonsContainer } from './styles';

interface ScrollButtonProps extends ButtonProps {
  field: string;
}

const ScrollButton = ({ field, ...rest }: ScrollButtonProps) => {
  return <Button {...rest} onClick={() => scroll(field)} />;
};

const scroll = (field: string) => {
  const index = tableItensColumns.findIndex(
    (column: CustomGridColumns) => column.field === field,
  );
  const columns = tableItensColumns.slice(0, index);
  let offset = 0;
  columns.map((column: CustomGridColumns, _index) => {
    if (_index + 1 !== columns.length) {
      offset += column.width || 0;
    }
    return column;
  });
  if (offset === 0) {
    document.getElementsByClassName(
      'tableEmissaoNFeContainer',
    )[0].scrollLeft = 0;
    return;
  }
  const tableElement = document.querySelector<HTMLElement>('#tableEmissaoNFe');
  const tableWidth = tableElement?.offsetWidth || 0;
  if (offset >= tableWidth) {
    document.getElementsByClassName('tableEmissaoNFeContainer')[0].scrollLeft =
      tableWidth;
    return;
  }
  document.getElementsByClassName('tableEmissaoNFeContainer')[0].scrollLeft =
    offset;
};

export const ScrollButtons: React.FC = () => {
  return (
    <>
      <ScrollButtonsContainer>
        <ScrollButton field="num_item">Identificador</ScrollButton>
        <ScrollButton field="qtd_embalagem_venda">Quantidade</ScrollButton>
        <ScrollButton field="val_desconto">Variáveis</ScrollButton>
        <ScrollButton field="val_tabela_liq">ICMS</ScrollButton>
        <ScrollButton field="val_icms_st">ST</ScrollButton>
        <ScrollButton field="tipo_tributacao">Tributação</ScrollButton>
      </ScrollButtonsContainer>
    </>
  );
};
