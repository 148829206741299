import React, { useCallback, useEffect, useState } from 'react';

import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

import { tableItensColumns } from './TableItens';

import { TableContainer, Container, ButtonRow } from './styles';
import { ScrollButtons } from './ScrollButtons';
import CustomDataGrid from '../CustomDataGrid';
import { Produto } from '../../../../protocols';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { Col, Row } from 'react-bootstrap';
import { moneyFormat } from '~/utils/functions';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { deleteItens } from '~/utils/masterDetail';

const MySwal = withReactContent(Swal);

export const TableItens: React.FC = () => {
  const {
    produtos,
    setProdutos,
    setProdutoSelecionado,
    produtoSelecionado,
    cuponsReferenciados,
    isUpdate,
    subTotais,
    setIsFormItendEditing,
    formItens: { setValue, reset },
    masterDetail,
    setMasterDetail,
  } = useEmissaoNFE();

  const [itens, setItens] = useState<Produto[]>([]);

  const handleRemoveItem = useCallback(
    async (
      num_item: number,
      cod_produto: string,
      des_produto: string,
      uuid: string,
    ) => {
      if (
        produtoSelecionado.cod_produto === cod_produto &&
        produtoSelecionado.num_item === num_item
      ) {
        // aq
        const confirmaRemocao = await MySwal.fire({
          title: 'O item encontra-se em edição, deseja realmente remove-lo?',
          text: des_produto,
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (confirmaRemocao) {
          // Remove produto da lista

          const produtosDetail: any[] = await deleteItens(
            'produtos',
            uuid,
            masterDetail,
            setMasterDetail,
          );
          setProdutos(produtosDetail);

          reset();
          setIsFormItendEditing({ isEdit: false, uuid: null });
        }
        return;
      }
      // Confirma remoção do item da tabela
      const confirmaRemocao = await MySwal.fire({
        title: 'Deseja remover o item?',
        text: des_produto,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (confirmaRemocao) {
        // Remove produto da lista
        const produtosDetail: any[] = await deleteItens(
          'produtos',
          uuid,
          masterDetail,
          setMasterDetail,
        );
        setProdutos(produtosDetail);
      }
    },
    [
      itens,
      produtoSelecionado.cod_produto,
      produtoSelecionado.num_item,
      reset,
      setProdutoSelecionado,
      setProdutos,
    ],
  );
  const handleEditItem = useCallback(
    async (uuid: string) => {
      // Editar item selecionado
      const produtoSelecionado2 = produtos.find(
        (produto) => produto.uuid === uuid,
      );
      if (produtoSelecionado2) {
        setValue('busca_produto', {
          label: `${produtoSelecionado2.cod_produto} - ${produtoSelecionado2.des_produto}`,
          value: Number(produtoSelecionado2.cod_produto),
          ...produtoSelecionado2,
        });
        setValue('tributacao', {
          label: `${produtoSelecionado2.cod_tributacao} - ${produtoSelecionado2.des_tributacao}`,
          value: Number(produtoSelecionado2.cod_tributacao),
        });
        setValue('qtd_entrada', Number(produtoSelecionado2.qtd_total));
        setValue(
          'val_tabela',
          moneyFormat(String(produtoSelecionado2.val_tabela), 2),
        );
        setValue(
          'desconto',
          moneyFormat(String(produtoSelecionado2.val_desconto), 2),
        );
        setValue(
          'acrescimo',
          moneyFormat(String(produtoSelecionado2.val_acrescimo), 2),
        );
        setProdutoSelecionado(produtoSelecionado2);
        setValue('cfop', produtoSelecionado2.cfopData);
        setValue('tributacao', produtoSelecionado2.tribData);
        setIsFormItendEditing({ isEdit: true, uuid });
      }
    },
    [produtos, setIsFormItendEditing, setProdutoSelecionado, setValue],
  );

  const actions: CustomGridColumns[] = [
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'right',
      width: 200,
      renderCell: (row: any) => {
        return (
          <div>
            <ButtonRow
              type="button"
              disabled={isUpdate}
              onClick={() => {
                handleEditItem(row.uuid);
              }}
              style={{ marginRight: '10px' }}
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow
              type="button"
              disabled={isUpdate}
              onClick={() => {
                handleRemoveItem(
                  row.num_item,
                  row.cod_produto,
                  row.des_produto,
                  row.uuid,
                );
              }}
              style={{ marginRight: '10px' }}
            >
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </div>
        );
      },
    },
  ];

  const columns: CustomGridColumns[] = [...tableItensColumns, ...actions];

  useEffect(() => {
    const data = produtos.map((produto: Produto) => {
      return {
        // caso seja necessária alguma alteração nos itens insira abaixo
        ...produto,
      };
    });
    setItens(data);
  }, [produtos]);

  return (
    <Container>
      <ScrollButtons />
      <TableContainer>
        <CustomDataGrid
          rows={itens}
          columns={
            cuponsReferenciados.length <= 0 ? columns : tableItensColumns
          }
          stickyMenuBackground="#eafffe"
        />
      </TableContainer>
      <Row>
        <Col md={12} sm={12}>
          <table className="table">
            <tbody>
              <tr>
                <td>Total BC: {moneyFormat(String(subTotais.totalBC), 2)}</td>
                <td>
                  Valor ICMS: {moneyFormat(String(subTotais.totalICMS), 2, 2)}
                </td>
                <td>
                  Total NF: {moneyFormat(String(subTotais.totalNF), 2, 2)}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};
