import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as yup from 'yup';
import { ConfirmButton } from '~/components/Buttons';
import { InputDate, InputNumber, InputSelect } from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputAsyncSelect/protocols';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { CalculosDaTabela } from '~/pages/EmissaoNFE/functions/Calculos';
import { CuponsReferenciados, Pdv } from '~/pages/EmissaoNFE/protocols';
import api from '~/services/api';

import { BootstrapModal } from './styles';

const MySwal = withReactContent(Swal);

interface ModalProps {
  handleModal: {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

const schema = yup.object().shape({
  dta_cupom: yup.string().required(),
  num_cupom: yup.number().required(),
  pdv: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
});

export const Modal: React.FC<ModalProps> = (props) => {
  const [loadingCupom, setLoadingCupom] = useState<boolean>(false);
  const { handleModal } = props;
  const {
    codLoja,
    cuponsReferenciados,
    setCuponsReferenciados,
    loja,
    parceiro,
    produtos,
    setProdutos,
    formNFE: { getValues: getFormNFEValues },
    formItens: { reset: resetFormItens },
  } = useEmissaoNFE();
  /**
   * Form NFE
   */
  const { register, handleSubmit, control, setValue, getValues, formState } =
    useForm({
      resolver: yupResolver(schema),
      reValidateMode: 'onBlur',
    });

  const [pdvs, setPdvs] = useState<SelectType[]>([]);
  const [inputPdv, setInputPdv] = useState<number>();

  const getCupomData = useCallback(async () => {
    const dataEmissao = getValues('dta_cupom');
    const numCupom = getValues('num_cupom');
    const { num_pdv } = getValues('pdv');

    try {
      const { data } = await api.get('/cupom', {
        params: {
          cod_loja: codLoja,
          dta_emissao: dataEmissao,
          num_cupom: numCupom,
          num_pdv,
        },
      });
      return data.data;
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
      return [];
    }
  }, [codLoja, getValues]);

  const getCupomItems = useCallback(async () => {
    const dtaCupom = getValues('dta_cupom');
    const numCupom = getValues('num_cupom');
    const { num_pdv } = getValues('pdv');

    try {
      const { data } = await api.get('/cupom/items', {
        params: {
          cod_loja: codLoja,
          num_cupom: numCupom,
          num_pdv,
          dta_cupom: dtaCupom,
        },
      });
      return data.data;
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
      return [];
    }
  }, [codLoja, getValues]);

  const handleCuponsReferenciados = useCallback(
    (itensDoCupom: string[], cod_seq_cupom: string, num_cupom: string) => {
      function verificaItensDoCupomReferenciado(cupom: CuponsReferenciados) {
        /**
         * Nesse método é efetuado um merge dos itens já referenciados a NF com os itens selecionados na tabela.
         * Após o merge é efetuado um reduce com a finalidade de remover itens duplicados.
         * Após o reduce os itens são atualizados na NF referenciada.
         */
        const array = [...cupom.itens, ...itensDoCupom].reduce(
          (unique: any, item: any) => {
            return unique.includes(item) ? unique : [...unique, item];
          },
          [],
        );
        setCuponsReferenciados((prev) =>
          prev.map((item) => {
            if (item.cod_seq_cupom === cupom.cod_seq_cupom) {
              item.itens = array;
            }
            return item;
          }),
        );
      }
      /**
       * Verifica se NF já está referenciada
       */
      const nfExists = cuponsReferenciados.find(
        (nf) => nf.cod_seq_cupom === cod_seq_cupom,
      );
      if (nfExists) {
        /**
         * Caso a NF já esteja referenciada o método verificaItensDoCupomReferenciado será chamado
         * com a finalidade de atualizar a lista dos itens vinculados a essa NF.
         */
        verificaItensDoCupomReferenciado(nfExists);
        return;
      }
      /**
       * Caso a NF ainda não esteja referenciada, ela será adicionada a lista de NFs referenciadas.
       */
      setCuponsReferenciados((prev) => [
        ...prev,
        {
          cod_seq_cupom,
          num_cupom,
          itens: itensDoCupom,
        },
      ]);
    },
    [cuponsReferenciados, setCuponsReferenciados],
  );
  const handleBuscaNf = handleSubmit(async (data) => {
    const cupomExists = cuponsReferenciados.find(
      (cupom) => cupom.num_cupom === data.num_cupom,
    );
    if (cupomExists) {
      toast.warning('Cupom já incluso nessa NF');
      return;
    }
    const { flg_orgao_publico } = getFormNFEValues('busca_parceiro');
    const cupomData = await getCupomData();
    if (cupomData.length <= 0 || cupomData[0].flg_existe === false) {
      toast.warning('Cupom não encontrado');
      return;
    }
    if (cupomData[0].num_nf > 0) {
      const confirmaRecalculo = await MySwal.fire({
        title: `Esse cupom já foi utilizado para a Nota Fiscal nº ${cupomData[0].num_nf}`,
        text: 'Deseja utilizá-lo novamente? ',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (confirmaRecalculo) {
        // Buscar dados do cupom
        const cupomItems = await getCupomItems();
        if (cupomItems) {
          const formatedItems = await CalculosDaTabela({
            itens: cupomItems,
            flg_orgao_publico,
            tipo_regime: loja.tipo_regime,
            des_sigla: loja.uf,
            cfop: loja?.uf === parceiro?.des_uf ? '5929' : '6929',
            lastIndex: produtos.length,
          });
          const listaDeItensPorCupomReferenciado = [];
          for (let i = 0; i < formatedItems.length; i++) {
            listaDeItensPorCupomReferenciado.push(formatedItems[i].cod_produto);
          }
          if (produtos.length + formatedItems.length > 500) {
            toast.warning('Limite de 500 items excedido.');
            return;
          }
          setProdutos([...produtos, ...formatedItems]);
          handleCuponsReferenciados(
            listaDeItensPorCupomReferenciado,
            cupomData[0].cod_seq_cupom,
            cupomData[0].num_cupom,
          );

          resetFormItens();
          handleModal.setShowModal(false);
        }
        return;
      }
      return;
    }
    // Buscar dados do cupom
    const cupomItems = await getCupomItems();
    if (cupomItems) {
      const formatedItems = await CalculosDaTabela({
        itens: cupomItems,
        flg_orgao_publico,
        tipo_regime: loja.tipo_regime,
        des_sigla: loja.uf,
        cfop: loja?.uf === parceiro.des_uf ? '5929' : '6929',
        lastIndex: produtos.length,
      });
      const listaDeItensPorCupomReferenciado = [];
      for (let i = 0; i < formatedItems.length; i++) {
        listaDeItensPorCupomReferenciado.push(formatedItems[i].cod_produto);
      }
      if (produtos.length + formatedItems.length > 500) {
        toast.warning('Limite de 500 items excedido.');
        return;
      }
      setProdutos([...produtos, ...formatedItems]);
      handleCuponsReferenciados(
        listaDeItensPorCupomReferenciado,
        cupomData[0].cod_seq_cupom,
        cupomData[0].num_cupom,
      );

      resetFormItens();
      handleModal.setShowModal(false);
    }
  });

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/pdvs/${codLoja}`);

      if (!data.success) {
        toast.error(
          'Não foi possível carregar os PDVs para a Loja selecionada.',
        );
        return;
      }
      const options = data.data.map((pdv: Pdv) => {
        return {
          value: pdv.num_pdv,
          label: String(pdv.num_pdv),
          ...pdv,
        };
      });

      setPdvs(options);
    })();
  }, [codLoja]);

  return (
    <BootstrapModal
      show={handleModal.showModal}
      onHide={() => handleModal.setShowModal(false)}
      centered
      size="lg"
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title>Inserir Cupom</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <Row style={{ marginBottom: '80px' }}>
          <Col md={3} sm={12}>
            <InputDate
              register={register}
              label="Emissão"
              name="dta_cupom"
              control={control}
              isError={!!formState.errors.dta_cupom}
            />
          </Col>
          <Col md={3} sm={12}>
            <InputNumber
              label="Número"
              max={100000}
              maxLength={50}
              placeholder="0"
              name="num_cupom"
              register={register}
              disabled={false}
              isError={!!formState.errors.num_cupom}
            />
          </Col>
          <Col md={3} sm={12}>
            <InputSelect
              label="Caixa"
              maxLength={50}
              placeholder="Selecione..."
              value={inputPdv}
              onChangeCapture={(event: any) => {
                const numPdv = event.target.value;
                setInputPdv(Number(numPdv));
              }}
              onBlur={() => {
                // eslint-disable-next-line array-callback-return
                const selected = pdvs.find((i: any) => {
                  return i.num_pdv === inputPdv;
                });
                if (selected) {
                  setValue('pdv', selected);
                  setInputPdv(undefined);
                }
              }}
              name="pdv"
              register={register}
              isError={!!formState.errors.pdv}
              control={control}
              options={pdvs}
              changeSelected={(selected) => {
                setValue('pdv', selected);
                setInputPdv(undefined);
              }}
            />
          </Col>
          {/* PESQUISAR */}
          <Col
            md={3}
            sm={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <ConfirmButton
              onClick={async () => {
                setLoadingCupom(true);
                await handleBuscaNf();
                setLoadingCupom(false);
              }}
              style={{
                color: '#ffffff',
                // background: '#8850BF',
                height: '100%',
                maxHeight: '42px',
              }}
            >
              {loadingCupom ? (
                <CircularProgress size={15} style={{ color: '#ffffff' }} />
              ) : (
                'Adicionar'
              )}
            </ConfirmButton>
          </Col>
        </Row>
      </BootstrapModal.Body>
      {/* <BootstrapModal.Footer> */}
      {/* <Button variant="secondary" onClick={() => null}>
          Adicionar
        </Button> */}
      {/* </BootstrapModal.Footer> */}
    </BootstrapModal>
  );
};
