import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schemaFormItens = yup
  .object({
    busca_produto: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    tributacao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cfop: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    qtd_entrada: yup
      .string()
      .required()
      .test((val) => {
        return Number(val) > 0;
      }),
    // .test((val) => {
    //   const valueToFloat = transformAsCurrency(val || 0);
    //   return valueToFloat >= 0;
    // }),
    val_tabela: yup.string().required(),
    // .test((val) => {
    //   const valueToFloat = transformAsCurrency(val || 0);
    //   return valueToFloat >= 0;
    // }),
    subtotal: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat >= 0;
      }),
  })
  .required();
