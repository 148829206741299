import styled from 'styled-components';

export const Button = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;
  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: #eda934 !important;
  }
`;

export const ContainerReferecia = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-height: 60px;
  padding: 10px 20px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  justify-content: start;
  align-items: center;
  gap: 5px 5px;
`;

export const CardReferecia = styled.div`
  display: flex;
  min-height: 15px;
  min-width: 70px;
  padding: 5px 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.nav};
  color: #fff;
`;
