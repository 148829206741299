import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText, moneyFormat } from '~/utils/functions';
import { Produto } from '../../../../../protocols';

export const quantidadeColumns: CustomGridColumns[] = [
  {
    field: 'qtd_embalagem_venda',
    headerName: 'Qtd. Embalagem',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_total_produto',
    headerName: 'Qtd. Saída',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { qtd_total_produto } = row;
      return <span>{formatCurrencyAsText(qtd_total_produto)}</span>;
    },
  },
  {
    field: 'qtd_total',
    headerName: 'Qtd. Total',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { qtd_total } = row;
      return <span>{formatCurrencyAsText(qtd_total)}</span>;
    },
  },
  {
    field: 'val_tabela',
    headerName: 'Valor Tabela',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_tabela } = row;
      return <span>{moneyFormat(String(val_tabela || 0), 2)}</span>;
    },
  },
  {
    field: 'val_total',
    headerName: 'Total Tabela',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: Produto,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const { val_total } = row;
      return <span>{moneyFormat(String(val_total || 0), 2)}</span>;
    },
  },
];
